@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&subset=latin-ext");

html {
	position: relative;
	min-height: 100%;
}

body {
	min-height: 100%;
	overflow-x: hidden;
	font-family: "Lato", sans-serif;

	a,
	ul,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
		font-weight: 300;
	}

	a {
		outline: 0 !important;
	}

	p {
		font-size: 14px;
		line-height: 25px;
	}

	.section-heading {
		h1 {
			font-size: 26px;
		}

		p {
			font-weight: 300;
			color: #666;
			font-size: 14px;
		}
	}

	.section-heading-small {
		p {
			color: #999;
			font-size: 12px;
			text-transform: uppercase;
		}
	}

	.btn-default {
		border: 1px solid @color1;
		background: @color1;
		color: #fff;
		text-transform: uppercase;
		transition: all 0.3s;
		font-size: 13px;
		padding: 8px 18px;

		&:hover {
			border-color: darken(@color1, 10%);
			background: darken(@color1, 10%);
			color: #fff;
		}
	}

	.btn-link {
		display: inline-block;
		text-decoration: none;
		text-transform: uppercase;
		font-style: italic;
		font-size: 12px;
		transition: all 0.3s;
		color: @gray-light;

		&.btn-buy {
			margin-top: 10px;
			display: block;
			background-color: #333;
			color: #fff;
			padding: 5px 15px;

			&:hover, &:focus {
				color: #fff;
				background: #555;
			}
		}

		&:hover,
		&:focus {
			color: @gray-dark;
			text-decoration: none;
		}
	}

	.down-chevron {
		display: flex;
		justify-content: center;
		height: 31px;
	}

	.m-t-5 {
		margin-top: 5px;
	}
	.m-t-10 {
		margin-top: 10px;
	}
	.m-t-15 {
		margin-top: 15px;
	}
	.m-t-20 {
		margin-top: 20px;
	}
	.m-t-25 {
		margin-top: 25px;
	}
	.m-t-30 {
		margin-top: 30px;
	}
	.m-b-5 {
		margin-bottom: 5px;
	}
	.m-b-10 {
		margin-bottom: 10px;
	}
	.m-b-15 {
		margin-bottom: 15px;
	}
	.m-b-20 {
		margin-bottom: 20px;
	}
	.m-b-25 {
		margin-bottom: 25px;
	}
	.m-b-30 {
		margin-bottom: 30px;
	}
	.p-t-5 {
		padding-top: 5px;
	}
	.p-t-10 {
		padding-top: 10px;
	}
	.p-t-15 {
		padding-top: 15px;
	}
	.p-t-20 {
		padding-top: 20px;
	}
	.p-t-25 {
		padding-top: 25px;
	}
	.p-t-30 {
		padding-top: 30px;
	}
	.p-b-5 {
		padding-bottom: 5px;
	}
	.p-b-10 {
		padding-bottom: 10px;
	}
	.p-b-15 {
		padding-bottom: 15px;
	}
	.p-b-20 {
		padding-bottom: 20px;
	}
	.p-b-25 {
		padding-bottom: 25px;
	}
	.p-b-30 {
		padding-bottom: 30px;
	}
}

a.fixed-button {
	background: #333;
	right: -150px;
	width: 150px;
	height: 40px;
	display: block;
	position: fixed;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	opacity: 0.5;
	transition: all 0.5s;

	p {
		color: #fff;
		text-align: center;
	}

	&.shop-online {
		top: 40%;

		&:after {
			background-image: url(../img/pictures/cart-outline.png);
		}
	}

	&.liqpharm {
		margin-top: 50px;
		top: 40%;

		&:after {
			background-image: url(../img/logos/liqpharm-sygnet.png);
		}
	}

	&.facebook {
		margin-top: 100px;
		top: 40%;

		&:after {
			background-image: url(../img/logos/facebook.png);
		}
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: -40px;
		height: 40px;
		width: 40px;
		background-color: #333;
		background-position: center;
		background-repeat: no-repeat;
	}

	&:hover {
		right: 0;
		opacity: 1;
	}
}

.products-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.product-service {
		width: 200px;
		text-align: center;
		margin-top: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.service-image {
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 185px;
			position: relative;
			transform: scale(0.94);
			transition: all 0.3s;

			img {
				width: 80%;
			}
		}

		.service-info {
			padding: 10px;
			margin-top: 10px;
			transition: all 0.3s;

			a {
				color: #333;
				text-decoration: none;
				font-size: 16px;
				transition: all 0.3s;
				display: block;
				width: 100%;

				&.product-subname {
					font-size: 14px;
				}

				&.product-short-description {
					font-size: 12px;
					margin-top: 10px;
				}
			}
		}

		p.price {
			font-size: 20px;
			color: #444;
			margin-top: 10px;

			&:after {
				color: #444;
				content: "€";
				font-size: 10px;
				margin-left: 5px;
			}
		}

		&.product-promotion {
			p.price {
				color: #ee0945;

				span {
					margin-right: 4px;
					color: #444;
					text-decoration: line-through;
				}
			}
		}

		&.service-logo {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 70%;
				max-width: 100%;
			}

			&.service-hidden-lg {
				display: none;
			}
		}

		&:hover {
			.service-image {
				transform: scale(1);
			}
		}
	}
}

.products-location {
	padding-top: 80px;

	.section-heading {
		text-decoration: none;
		display: block;

		.heading-text {
			background: #333;
			padding: 10px 0;
		}

		h1 {
			color: #fff;
			margin-bottom: 10px;
		}

		p {
			color: #fff;
		}

		img {
			margin: 0 auto;
		}
	}

	.description {
		margin-top: 50px;
		padding-bottom: 80px;
	}

	.logo-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 10px;
		padding-bottom: 70px;

		a {
			margin-top: 30px;
			width: 11.3%;
			transition: all 0.3s;

			img {
				width: 100%;
			}

			&:hover {
				transform: scale(1.06);
			}

			&:nth-child(1n + 5) {
				margin-top: 30px;
			}

			&.service-hidden {
				margin: 0 !important;
				padding: 0 !important;
				visibility: hidden;
				opacity: 0;
			}
		}
	}
}

.news-flexbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	.news-service {
		text-align: center;
		width: 250px;

		.service-image {
			width: 100%;
			height: 150px;
			overflow: hidden;
			display: flex;
			align-items: center;

			img {
				width: 100%;
			}
		}

		h2 {
			font-size: 16px;
			margin: 15px 0 8px 0;
			padding: 0 5px;
			line-height: 23px;
			height: 46px;

			a {
				text-decoration: none;
				color: #000;
			}
		}

		p {
			margin-bottom: 10px;
			font-size: 12px;
			color: #999;

			&:before {
				content: "Opublikowano: ";
			}
		}

		&.news-hidden {
			visibility: hidden;
			opacity: 0;
			margin: 0 !important;
			padding: 0 !important;
		}
	}
}

.produkt-image-bottom {
	margin-top: -30px;
	position: relative;
	z-index: -1;
}

.pagination {
	margin-bottom: 0;
	margin-top: 50px;

	li {
		a {
			background: #fff;
			border: 1px solid @gray-lighter;
			color: #000;
		}

		&.active {
			a {
				background: #000;
				border-color: #000;

				&:hover {
					background: #000;
					border-color: #000;
				}
			}
		}
	}
}

div#section-header {
	position: relative;

	nav.navbar {
		min-height: auto;
		margin-bottom: 0;
		border: none;
		border-radius: 0;
		background: none;

		ul.navbar-nav {
			li {
				padding: 0;

				a {
					padding: 17px 22px;
					text-transform: uppercase;
					color: #444;
					background: none;
					transition: all 0.3s;
				}

				&.account {
					a {
						color: lighten(@gray-light, 20%);
						padding-right: 10px;

						img {
							max-height: 16px;
							opacity: 0.8;
						}
					}

					&:last-child {
						a {
							padding-left: 10px;
							padding-right: 0;
							border: none;
							position: relative;

							&:before {
								content: "";
								height: 10px;
								background: darken(@gray-lighter, 10%);
								width: 1px;
								position: absolute;
								left: 0;
								top: 25px;
							}
						}
					}

					&:hover {
						a {
							background: none;
							color: lighten(@gray-light, 20%);
						}
					}
				}

				&:hover {
					a {
						color: #fff;
						background: #444;
					}
				}

				&.link-actived {
					a {
						color: #fff;
						background: #333;
					}
				}
			}
		}

		.navbar-brand {
			height: auto;

			img {
				width: 250px;
			}
		}

		.breakpoint {
			display: none;
		}

		.mobile-cart {
			img {
				height: 22px;
			}
		}

		.navbar-toggle {
			padding: 0;
			margin: 20px 15px 0 0;
			border: none;
			border-radius: 0;

			span {
				background-color: #000;
			}

			&:hover,
			&:focus {
				background: none;
			}
		}
	}
}

div#section-main-carousel {
	.owl-carousel-slider {
		.item {
			width: 100vw;
			height: 550px;
			display: flex;
			padding-left: 105px;
			justify-content: center;
			flex-direction: column;

			.item-description {
				width: 590px;

				h2 {
					color: #fff;
					font-weight: 300;
					font-size: 50px;
				}

				p {
					color: #fff;
					padding: 10px 0;
				}
			}

			&.bg1 {
				background: url(../img/backgrounds/fotolia_66420396.jpg) center;
				background-size: cover;

				h2,
				p {
					color: #fff;
				}
			}

			&.bg2 {
				background: url(../img/backgrounds/fotolia_100916405.jpg) center;
				background-size: cover;

				h2,
				p {
					color: #fff;
				}
			}

			&.bg3 {
				background: url(../img/backgrounds/fotolia_55277579.jpg) center;
				background-size: cover;
			}
		}

		.owl-controls {
			.owl-dots {
				margin-top: -35px;
				position: relative;
				z-index: 999;
			}
		}
	}
}

div#section-about {
	padding-bottom: 80px;
	margin: 50px 0 80px 0;
	position: relative;
	text-align: center;
	background: #333;

	.section-heading {
		padding-top: 78px;

		h1 {
			margin-bottom: 10px;
			color: #fff;
		}

		p {
			color: #fff;
		}

		.btn {
			margin-top: 15px;
			color: #fff;
		}
	}
}

div#section-brands {
	padding: 80px 0;
	background: url(../img/backgrounds/pexels-photo-132185.jpeg) center;
	background-size: cover;
	background-attachment: fixed;

	.brands-flexbox {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;

		.brand-service {
			text-align: center;
			position: relative;
			border-radius: 50%;
			transition: all 0.3s;

			.service-image {
				width: 290px;
				height: 290px;
				display: block;
				overflow: hidden;
				border-radius: 50%;
				position: relative;
				transition: all 0.3s;
				outline: 0;

				.image-cover {
					background: #333;
					overflow: hidden;
					border-radius: 50%;
					position: absolute;
					width: 100.5%;
					height: 100.5%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					padding: 5px 10px;
					opacity: 0;
					transition: all 0.3s;

					span {
						color: lighten(@gray-light, 10%);
						text-transform: uppercase;
						font-size: 11px;
						font-style: italic;
					}
				}

				.image-logo {
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					transition: all 0.3s;

					img {
						max-height: 70px;
					}
				}
			}

			p {
				margin-top: 15px;
				margin-bottom: 15px;
				color: #fff;

				strong {
					font-weight: 300;
					font-size: 18px;
				}
			}

			&.bg1 {
				background: url(../img/pictures/ubbu-backgr.jpg) center;
				background-size: cover;
			}

			&.bg2 {
				background: url(../img/pictures/liqc-backgr.jpg) center;
				background-size: cover;
			}

			&.bg3 {
				background: url(../img/pictures/furi-backgr.jpg) center;
				background-size: cover;
			}

			&:hover {
				background: none;

				.service-image {
					.image-cover {
						opacity: 1;
					}
					.image-logo {
						opacity: 0;
					}
				}
			}
		}
	}
}

div#section-news {
	padding-bottom: 80px;

	.content-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.section-heading {
			p {
				max-width: 300px;
			}

			.btn {
				margin-top: 15px;
			}
		}

		.news-flexbox {
			justify-content: space-around;

			.news-service {
				&:nth-child(2) {
					margin: 0 15px;
				}
			}
		}
	}
}

div#o-firmie-section-welcome {
	padding: 80px 0;

	.welcome-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-heading {
			background: #fff;
			padding-right: 0;
			height: 100%;
			min-width: 460px;
			max-width: 460px;

			h1 {
				margin-bottom: 10px;
			}

			.btn {
				margin-top: 15px;
			}
		}

		.flexbox-personel {
			display: flex;
			justify-content: center;
			min-width: 600px;
			max-width: 600px;

			.personel-service {
				width: 28%;
				margin-right: 8%;
				text-align: center;

				img {
					width: 100%;
				}

				h4 {
					margin: 15px 0 6px 0;
					font-weight: 400;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

div#o-firmie-section-content {
	padding-bottom: 80px;

	.gallery-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		a {
			width: 31%;
			height: 230px;
			overflow: hidden;
			display: flex;
			align-items: center;

			img {
				width: 100%;
			}

			&:nth-child(1n + 4) {
				margin-top: 30px;
			}
		}
	}
}

div.brand-section-welcome {
	margin-top: 50px;

	.welcome-flexbox {
		position: relative;
		padding: 50px 0;

		.img-absolute {
			position: absolute;
			top: 0;
			left: 0;
			width: 44%;
			height: 100%;
			overflow: hidden;
			display: flex;
			align-items: center;

			img {
				width: 100%;
			}
		}

		.flexbox-description {
			width: 53%;
			margin-left: 47%;

			h1 {
				font-size: 26px;
				padding-bottom: 10px;
			}

			p {
				font-weight: 300;
				color: #666;
				font-size: 14px;
			}

			.btn {
				margin-top: 15px;
			}
		}

		&.flexbox-reverse {
			.img-absolute {
				right: 0;
				left: auto;
			}

			.flexbox-description {
				margin-left: 0;
			}
		}
	}
}

div#brand-section-products {
	padding-bottom: 80px;
}

div#brand-section-welcome-bottom {
	position: relative;
	background: url(../img/backgrounds/fotolia_66420660_v2.jpg) center right;
	background-size: contain;
	background-repeat: no-repeat;

	.section-heading {
		background: #fff;
		padding: 50px 50px;
		padding-left: 0;
		height: 100%;

		h1 {
			margin-bottom: 10px;
		}

		.btn {
			margin-top: 15px;
		}
	}
}

div#produkt-section-content {
	.single-product-flexbox {
		display: flex;

		.flexbox-gallery {
			margin-top: 80px;
			min-width: 250px;
			max-width: 250px;
			margin-right: 50px;
		}

		.flexbox-description {
			margin-top: 80px;

			h1 {
				color: #000;
				font-size: 28px;
			}

			h2 {
				color: #000;
				font-size: 18px;
				margin-top: 10px;
			}

			p.price {
				font-size: 28px;
				color: #000;
				margin-top: 30px;
				margin-bottom: 20px;

				&:after {
					color: #000;
					content: "€";
					font-size: 10px;
					margin-left: 5px;
				}
			}

			a.btn-link {
				font-size: 15px;
				transition: all 0.3s;
				align-self: center;
				padding: 5px 15px;
			}

			.info-heading {
				margin-top: 50px;
			}

			.info-text {
				p {
					color: #888;
				}
			}

			.description-buttons {
				margin-top: 50px;
			}
		}

		&.product-promotion {
			.flexbox-description {
				p.price {
					color: #ee0945;

					span {
						margin-right: 4px;
						color: #000;
						text-decoration: line-through;
					}
				}
			}
		}
	}

	#single-product-description {
		padding-top: 40px;

		ul {
			margin-bottom: 0px;
			display: flex;
			flex-wrap: wrap;

			li {
				padding: 0;

				a {
					padding: 17px 22px;
					text-transform: uppercase;
					color: #444;
					background: none;
					transition: all 0.3s;
					text-decoration: none;
					font-size: 12px;
					outline: 0;
					display: block;
				}

				&:hover {
					a {
						background: #444;
						color: #fff;
					}
				}

				&.active {
					a {
						background: #333;
						color: #fff;
					}
				}
			}
		}

		.tab-content {
			border-top: 1px solid @gray-lighter;
			padding-top: 20px;
		}
	}

	.products-flexbox-heading {
		text-align: center;
		margin-top: 80px;

		h2 {
			color: #000;
			font-size: 18px;
			margin-top: 10px;
		}
	}

	.products-flexbox {
		justify-content: center;

		.product-service {
			margin-top: 50px;
			width: 80px;
			margin-right: 20px;
			margin-left: 20px;

			.service-image {
				height: 111px;
			}

			.service-info {
				display: none;
			}

			.btn-link {
				display: none;
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

div#aktualnosci-section-content {
	padding: 80px 0;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.custom-search-input {
			width: 400px;

			input {
				border-radius: 0;
				border: 1px solid @gray-lighter;
				height: 40px;
				box-shadow: none;

				&:focus {
					box-shadow: none;
				}
			}

			button {
				background: darken(@gray-lighter, 10%);
				border: 1px solid darken(@gray-lighter, 10%);
				height: 40px;
				width: auto;
				border-radius: 0;
				color: #fff;
				text-transform: uppercase;
				font-style: italic;
				font-size: 12px;
			}
		}
	}

	.news-flexbox {
		.news-service {
			margin-top: 50px;
		}
	}
}

div#aktualnosc-section-content {
	text-align: center;
	padding: 80px 0;

	img {
		max-width: 100%;
		margin-bottom: 30px;
	}

	h1 {
		color: #000;
		font-size: 26px;
	}

	p {
		color: #000;
		margin-top: 30px;
	}

	p.date {
		margin-bottom: 10px;
		font-size: 12px;
		color: #999;
		margin-top: 0;

		&:before {
			content: "Opublikowano: ";
		}
	}

	.content-tags {
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		text-align: center;

		a {
			font-size: 12px;
			color: #999;
			text-decoration: none;
			display: block;
			padding: 3px;
		}

		&:before {
			content: "Chmura tagów: ";
			display: block;
			font-size: 12px;
			color: #999;
			width: 100%;
		}
	}
}

div#kontakt-section-content {
	padding-bottom: 80px;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			margin-top: 80px;
			width: 47%;

			h1 {
				font-size: 22px;
			}

			p {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			a {
				text-decoration: none;
				font-size: 18px;
				color: #000;
			}

			ul {
				margin-top: 20px;

				li {
					a {
						img {
							height: 30px;
						}
					}
				}
			}
		}

		form {
			margin-top: 80px;
			width: 47%;

			label {
				color: #999;
				margin-bottom: 5px;
				font-size: 11px;
				text-transform: uppercase;
				font-weight: 400;
			}

			input,
			textarea {
				border-radius: 0;
				box-shadow: none;

				&:focus {
					border-color: #000;
				}
			}
		}
	}
}

div#section-footer {
	padding: 50px 0;
	background: #333;

	.footer-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			.section-heading-small {
				p {
					color: #999;
					margin-bottom: 5px;
					font-size: 11px;
				}
			}

			p {
				color: #fff;
				font-size: 14px;
			}

			a {
				color: #fff;
				text-decoration: none;
			}

			ul {
				li {
					padding-bottom: 5px;

					a {
						font-size: 14px;
						color: #fff;

						img {
							height: 30px;
						}
					}

					&:last-child {
						padding-bottom: 0;
					}
				}
			}

			&.service-about {
				max-width: 260px;

				img {
					width: 200px;
					margin-bottom: 10px;
				}
			}

			&.service-info {
				p.question {
					color: #999;
					font-size: 14px;
					margin-top: 53px;
				}

				.custom-search-input {
					width: 280px;
					margin-top: 20px;

					input {
						border-radius: 0;
						border: 1px solid #fff;
						height: 32px;
					}

					button {
						background: #fff;
						height: 32px;
						width: 42px;

						i {
							color: #333;
						}
					}
				}
			}
		}
	}
}

@media (max-width: @screen-md-max) {
	.products-flexbox {
		.product-service {
			&.service-logo {
				width: 100%;

				img {
					width: auto;
					max-width: 100%;
				}

				&.service-hidden-lg {
					display: block;
				}

				&.service-visible-lg {
					display: none;
				}
			}
		}
	}

	div#section-news {
		.content-flexbox {
			flex-direction: column;

			.section-heading {
				text-align: center;
			}

			.news-flexbox {
				justify-content: space-between;
				width: 100%;

				.news-service {
					margin-top: 50px;

					&:nth-child(2) {
						margin: 50px 0 0 0;
					}
				}
			}
		}
	}

	div#o-firmie-section-welcome {
		.welcome-flexbox {
			.flexbox-heading {
				min-width: 330px;
				max-width: 330px;
			}

			.flexbox-personel {
				min-width: 500px;
				max-width: 500px;
			}
		}
	}

	div#o-firmie-section-content {
		.gallery-flexbox {
			a {
				height: 190px;
			}
		}
	}

	div#aktualnosci-section-content {
		padding: 80px 0;

		.news-flexbox {
			.news-service {
				&:nth-child(1n + 4) {
					margin-top: 50px;
				}
			}
		}
	}
}

@media (max-width: @screen-sm-max) {
	.products-flexbox {
		.product-service {
			width: 300px;

			.service-image {
				height: 278px;
			}
		}
	}

	div#section-header {
		position: relative;

		nav.navbar {
			.navbar-header {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:before, &:after {
					content: none;
				}

				.navbar-brand {
					order: 0;
					min-width: 230px;
					max-width: 230px;
				}

				.breakpoint {
					width: 100%;
					display: block;
					order: 1;
				}

				.mobile-cart {
					order: 2;
					margin-right: 10px;
				}

				.navbar-toggle {
					margin-top: 5px;
					order: 3;
				}
			}

			.navbar-collapse {
				border-top: 0;
				box-shadow: none;

				ul.navbar-nav {
					margin-top: 0;

					li {
						&:last-child,
						&:nth-last-child(2) {
							display: inline-block;
						}
					}
				}
			}
		}
	}

	div#section-news {
		.content-flexbox {
			.news-flexbox {
				.news-service {
					width: 220px;

					.service-image {
						height: 132px;
					}
				}
			}
		}
	}

	div.brand-section-welcome {
		margin-top: 0;

		.welcome-flexbox {
			padding-top: 0;

			.img-absolute {
				position: relative;
				width: 100%;
				height: auto;
			}

			.flexbox-description {
				width: 100%;
				margin-left: 0;
				text-align: center;
				margin-top: 50px;
			}
		}
	}

	div#section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				text-align: center;
				margin-top: 30px;

				&.service-about {
					img {
						max-width: 100%;
					}
				}

				&.service-info {
					max-width: 100%;

					p.question {
						margin-top: 20px;
					}

					.custom-search-input {
						max-width: 100%;
					}
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}
	}

	div#o-firmie-section-welcome {
		.welcome-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-heading {
				text-align: center;
				min-width: 500px;
				max-width: 500px;
			}

			.flexbox-personel {
				margin-top: 50px;
				min-width: 100%;
				max-width: 100%;
			}
		}
	}

	div#o-firmie-section-content {
		.gallery-flexbox {
			a {
				height: 145px;
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	.products-flexbox {
		flex-direction: column;
		align-items: center;

		.product-service {
			width: 200px;
			margin-top: 50px;

			.service-image {
				height: 185px;
			}
		}
	}

	div#section-main-carousel {
		.owl-carousel-slider {
			.item {
				padding-left: 0;

				.item-description {
					width: auto;
					text-align: center;
					padding: 0 30px;

					h2 {
						font-size: 25px;
					}
				}
			}
		}
	}

	div#section-brands {
		.brands-flexbox {
			flex-direction: column;

			.brand-service {
				&:nth-child(2) {
					margin: 50px 0;
				}
			}
		}
	}

	div#o-firmie-section-welcome {
		.welcome-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-heading {
				text-align: center;
				min-width: 100%;
				max-width: 100%;
			}

			.flexbox-personel {
				flex-direction: column;
				align-items: center;

				.personel-service {
					width: 52%;
					margin-right: 0;
					margin-top: 50px;

					&:first-child {
						margin-top: 0;
					}
				}
			}
		}
	}

	div#o-firmie-section-content {
		.gallery-flexbox {
			a {
				width: 100%;
				height: auto;
				margin-top: 30px;

				&:first-child {
					margin-top: 0;
				}
			}
		}
	}

	div#produkt-section-content {
		.products-flexbox {
			.product-service {
				width: 120px;
				margin-right: 0px;
				margin-left: 0px;

				.service-image {
					height: 111px;
				}

				.service-info {
					display: none;
				}

				.btn-link {
					display: none;
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	div#aktualnosci-section-content {
		padding: 80px 0;

		.content-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.custom-search-input {
				max-width: 100%;
				margin-top: 20px;
			}
		}
	}

	div#kontakt-section-content {
		.content-flexbox {
			align-items: center;
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				text-align: center;
			}

			form {
				width: 100%;
			}
		}
	}
}

@media (max-width: 720px) {
	.news-flexbox {
		flex-direction: column;

		.news-service {
			width: 250px;
			max-width: 100%;

			.service-image {
				height: auto;
			}
		}
	}
}

@media (max-width: 600px) {
	div#produkt-section-content {
		.single-product-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-gallery {
				width: 250px;
				min-width: auto;
				max-width: 100%;
				margin-right: 0px;
			}

			.flexbox-description {
				margin-top: 80px;

				h1 {
					color: #000;
					font-size: 28px;
				}

				h2 {
					color: #000;
					font-size: 18px;
					margin-top: 10px;
				}

				.info-heading {
					margin-top: 50px;
				}

				.info-text {
					p {
						color: #888;
					}
				}

				.description-buttons {
					margin-top: 50px;
				}
			}
		}

		#single-product-description {
			padding-top: 40px;

			ul {
				li {
					a {
						font-size: 10px;
					}

					&:hover {
						a {
							background-color: #444;
							color: #fff;
						}
					}

					&.active {
						a {
							background-color: #333;
							color: #fff;
						}
					}
				}
			}

			.tab-content {
				border-top: 1px solid @gray-lighter;
				padding-top: 20px;
			}
		}
	}
}

@media (max-width: 360px) {
	div#section-header {
		nav.navbar {
			.navbar-brand {
				margin-top: 2px;
				min-width: 200px;
				max-width: 200px;
			}
		}
	}
}

@media (max-width: 300px) {
	div#section-header {
		nav.navbar {
			.navbar-brand {
				margin-top: 4px;
				width: 200px;
			}
		}
	}
}
